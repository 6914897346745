body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100%;
  max-height: 100vh;
  height: 100vh;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

:root {
  --purple: #8c52ff;
  --green: #7ED957;
  --white: #FFFFFF;
  --gray: #f4f1f1;
  --email-blue: #1d8ae6;
  --delete-red: #ff302f;
  --link-blue: #5575f2;
}

.skeleton {
  background: #f4f1f1;
  background: linear-gradient(90deg, #f4f1f1 25%, #eae7e7 37%, #f4f1f1 63%);
  background-size: 200% 100%;
  animation: skeleton 1.5s infinite linear;
  width: 100%;
  height: 100%;
  min-height: 50px;
  border-radius: 10px;
}