.dashboard-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: auto;
    max-height: calc(100vh - 180px);
    scrollbar-width: none;
    -ms-overflow-style: none;
    text-transform: capitalize;
}