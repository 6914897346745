.bottom-bar, .bottom-bar-compact {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--white);
    color: white;
    bottom: 0;
    position: fixed;
    z-index: 100;
    height: 90px;
    overflow: hidden;
    border-top: 3px solid var(--purple);
}

.bottom-bar-compact {
    grid-template-columns: repeat(2,1fr);
}

.icon {
    height: 100%;
    font-size: 2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.selected-icon {
    background-color: var(--purple);
    color: var(--white) !important;
}
.fa-truck, .fa-home, .fa-cart-shopping, .fa-clipboard-list {
    color: var(--purple);
}