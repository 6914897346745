.orders-top {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 10px;
}

.order-filters {
    background-color: transparent;
    color: var(--purple);
    font-size: 1.2rem;
    font-weight: 700;
    border: none;
    gap: 10px;
    display: flex;
    text-transform: uppercase;
}

p {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.orders-top-search {
    display: flex;
    gap: 2px;
    align-items: center;
    width: 100%;
    height: fit-content;
}

.order-search {
    width: 100%;
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: 700;
}

.order-search::placeholder {
    font-size: 1.2rem;
    font-weight: 550;
    padding: 0 10px;
}

.fa-calendar-days {
    font-size: 1.5rem;
}

.orders-table {
    display: table;
    width: calc(100% - 1em);
    margin: 0 0.5em;
    border-collapse: separate;
    border-spacing: 0 0.5em;
    font-size: 12px;
}

.order-list-item {
    background-color: var(--gray);
    border-radius: 30px;
}

.th {
    background-color: var(--purple);
    color: var(--white);
    font-weight: 700;
    padding: 0.5rem 10px;
    border-radius: 30px;
}

.order-bill-action {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.1rem;
}

.icon-button-small {
    background-color: var(--purple);
    aspect-ratio: 1;
    border-radius: 30px;
}

.bill-delete {
    color: red;
    aspect-ratio: 1;
}

.order-bill-field {
    display: flex;
}

.orders-input-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    margin: 0 0 1rem 0;
}

.order-amount {
    display: flex;
    gap: 10px;
    align-items: center;
}

.order-action-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.order-delete-button {
    background-color: red;
}

.bill-file {
    display: flex;
    align-items: center;
    padding: 0 10px;
}

.order-search {
    width: 100%;
    border-radius: 30px;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0 10px;
}

.filter-label {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    padding: 0 5px;
}

.filter-check {
    display: flex;
    border: 1px solid black;
    border-radius: 30px;
    padding: 2px;
    margin: 0 4px;
}

.order-details-container {
    padding: 20px;
    margin: 0 auto;
    max-height: 75vh;
    overflow-y: auto;
}

.order-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.order-details-title {
    color: var(--purple);
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: bold;
}

/* Styling for the order items container */
.order-items-container {
    margin-top: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Styling for the table */
.order-items-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.order-items-table th {
    text-transform: capitalize;
}
.order-items-table th,
.order-items-table td {
    padding: 12px 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.order-items-table td {
    text-align: center; 
}
/* Table header styling */
.order-items-table th {
    background-color: var(--purple);
    color: white;
    font-weight: 600;
    font-size: 16px;
}

/* Table row styling */
.order-items-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

/* Table row hover effect */
.order-items-table tr:hover {
    background-color: #e6f7ff;
}

/* Table cell styling */
.order-items-table td {
    font-size: 15px;
    color: #333;
}

/* Responsive design for mobile devices */
@media screen and (max-width: 768px) {
    .order-details-container {
        padding: 10px;
    }
    .orders-input-div {
        width: 100%;
    }

    .order-items-table {
        font-size: 14px;
    }

    .order-items-table th,
    .order-items-table td {
        padding: 10px;
    }
}