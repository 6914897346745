.profile-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.profile-container form {
    display: flex;
    flex-direction: column;
}

.profile-container label {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.profile-container input[type="text"],
.profile-container input[type="email"],
.profile-container input[type="password"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.profile-container input[type="submit"] {
    background-color: var(--purple);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
}

.success {
    color: green;
    margin-bottom: 10px;
}

.error {
    color: red;
    margin-bottom: 10px;
}
