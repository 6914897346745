.product {
    background-color: var(--white);
    font-weight: 700;
    margin: 5px 0;
    border-radius: 30px;
    width: calc(100% - 2em);
    display: flex;
    align-items: center;
    gap: 0.3rem;
}

.products-search {
    margin:  0;
    width: calc(100% - 6px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 3px
}

.products-suppliers-select {
    display: flex;
    width: 100%;
    border-radius: 10px;
    font-size: 1rem;
    background-color: var(--gray);
/*    overflow scroll but hide scrollbar */
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 0 0 5px 0;
}

.product-supplier {
    font-weight: 400;
    border: none;
    padding: 0.5rem 10px;
    text-align: center;
    white-space: nowrap;
    text-transform: capitalize;
}

.selected-supplier {
    border-bottom: 2px solid var(--purple);
}

.products-list {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
	margin-bottom: 55px;
}

.product-name {
    background-color: var(--gray);
    width: 70%;
    font-weight: 500;
    padding: 0.5rem 10px;
    border-radius: 30px;
}

.product-actions {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.product-quantity {
    font-weight: 700;
    width: 30px;
    font-size: large;
    border: none;
    height: 100%;
    color: black;
}

.product-remove {
    background-color: red;
    aspect-ratio: 1;
    border: none;
}

.fa-minus {
    font-size: 1.5rem;
    color: var(--white);
}

.product-add {
    background-color: var(--green);
    aspect-ratio: 1;
    border: none;
}

.fa-plus {
    font-size: 1.5rem;
    color: var(--white);
}

.product-price {
    font-weight: 700;
    width: 100px;
    white-space: nowrap;
    padding: 3px;
}
.total-amount {
    width: 100%;
    text-align: right;
    padding: 0 10px;
    font-weight: 700;
    font-size:large;
}
.place-order-button-div {
    position: fixed;
    bottom: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.place-order-button {
    background-color: var(--purple);
    width: fit-content;
    padding: 15px 5px;
    border-radius: 3rem;
    color: var(--white);
    font-size: 1.3rem;
    font-weight: 600;
    border: none;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.choose-div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 20px 0;
	font-size: 1.1rem;
    font-weight: 700;
	text-transform: capitalize;
}

.choose-button {
    aspect-ratio: 1;
    height: 6em;
    border: none;
}
.mail{
    background-color: var(--email-blue);
    border-radius: 4em;
}
.download{
    background-color: black;
    border-radius: 4em;

}
.fa-envelope {
    font-size: 2rem;
    color: var(--white);
}
.fa-download {
    color: var(--white);
}

.fa-phone {
    font-size: 2rem;
    color: var(--white);
}

.phone{
    background-color: var(--green);
    border-radius: 1em;
}

.choose-ind {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}