.total-unpaid-bills-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
}

.main-title {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: 0 20px;
    color: var(--purple);
    margin: 0;
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
}
.total-unpaid-bills {
    gap: 5px;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    padding: 0 10px;
    border-radius: 10px;
}
.amount-component {
    border-radius: 30px;
    padding: 10px;
    background-color: var(--gray);
}

.unpaid-bill-title {
    font-size: 0.8rem;
    font-weight: 500;
}

.amount {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 700;
}