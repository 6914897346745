.suppliers-container {
    background-color: white;
}
.suppliers-search {
    width: calc(100% - 6px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 3px
}
.suppliers-list {
	padding: 10px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.supplier {
    background-color: var(--gray);
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 30px;
    width: calc(100% - 2em);
    margin: 0 10px;
    text-align: center;
    text-transform: capitalize;
}
.add-supplier-button{
    background-color: var(--purple);
    border-radius: 30px;
    border: 2px solid black;
    aspect-ratio:1;
    height: 40px;
}
.fa-add {
    font-size: 1.5rem;
    color: var(--white);
}

::placeholder {
    color: black;
    font-size: 1.5rem;
}

.add-supplier-form {
    background-color: var(--white);
    border-radius: 90px 90px 0 0;
    padding: 1rem;
    width: calc(100%);
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
}

.supplier-input-div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    margin: 0 0 1rem 0;
}

.supplier-input {
    width: 100%;
    padding: 5px 5px;
    font-size: 1em;
}
.add-supplier-heading {
    font-size: 1.1rem;
    font-weight: 700;
    margin: 0;
    padding: 0 20px;
    text-transform: uppercase;
	color: var(--purple);
}
.supplier-input::placeholder {
    font-size: 1.2em;
    font-weight: 500;
    padding: 0 10px;
    text-transform: capitalize;
}

.supplier-save-button {
    background-color: var(--purple);
    width: fit-content;
    padding: 15px 10px;
    margin: 5px 0;
    border-radius: 2rem;
    color: var(--white);
    font-size: 1em;
    font-weight: 600;
    border: none;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}
.add-supplier-top{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 20px 0;
}

.icon-button {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    border: none;

}

.supplier-top-button {
    background-color: transparent;
    border: none;
    color: var(--link-blue);
    margin: 0 10px;
    font-size: 1.5rem;
    font-weight: 800;
    text-decoration: underline;
    text-transform: capitalize;
}

.supplier-field {
    font-weight: 500;
    font-size: 1.1rem;
    /** max width of 90% */
    width: 90%;
    /** wrap text */
    word-wrap: break-word;
}

.error-text {
    color: red;
    font-size: 1.2rem;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.delete-confirmation {
    background-color: var(--white);
    border-radius: 90px 90px 0 0;
    padding: 1rem;
    width: calc(100%);
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 90px;
}
.skeleton-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    align-items: center;
}
.skeleton-chart, .skeleton-list, .skeleton-cell {
    width: 90%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background-color: #c3c9cc;
    border-radius: 30px;
    color: lightslategray;
}

.skeleton-list {
    height: 50px;
}

.skeleton-cell{
    height: 15px;
    width: 100%;
}