.login-container {
    background-color: var(--purple);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-elements{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo{
    width: 350px;
    height: 100px;
    margin: 30px 0;
}

.welcome {
    color: white;
    font-size: 0rem;
    font-weight: 500;
    text-transform: uppercase;
}

.input {
    margin: 0.5rem;
    width: 85%;
    padding: 0.4rem 1rem;
    border-radius: 3rem;
    border: 2px solid black;
    color: black;
    outline: none;
    font-size: 1.2rem;
}

::placeholder {
    color: black;
    font-weight: 400;
    font-size: Large;
    font-family: Calibri, sans-serif;
}

.button {
    background-color: var(--green);
    border: none;
    padding: 0.5rem 2rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: white;
    width: fit-content;
    border-radius: 3rem;
    margin: 1rem;
    text-transform: capitalize;
}

.error {
    color: white;
    font-size: 1rem;
    font-weight: 700;
}