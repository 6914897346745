.top-bar {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    justify-content: space-between;
    background-color: var(--purple);
    color: white;
    top: 0;
    position: sticky;
    z-index: 100;
    height: 90px;
    overflow: auto;
}

.title {
    font-size: 2rem;
    font-weight: 700;
    column-span: 2;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-transform: capitalize;
}

.button-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.btn {
    border: 2px solid white;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    background-color: transparent;
    color: white;
    font-size: 2rem;
    margin: 1rem;
}

.menu {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: white;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
    z-index: 1000;

}

.menu-item {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border: none;
    text-transform: capitalize;
    text-decoration: none;
    color: black;
}

.menu-item:hover {
    background-color: #f1f1f1;
}

.flag {
    margin-right: 10px;
    height: 20px;
}

.confirm-sign-out {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    position: absolute;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.4);
    padding: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 500;
    border-radius: 30px;
    font-size: 1.5rem;
    width: 80%;
}

.confirm-button, .yes, .no{
    border: 2px solid white;
    padding: 0.5rem 0.8rem;
    border-radius: 0.5rem;
    background-color: var(--purple);
    color: white;
    font-size: 2rem;
    margin: 1rem;
}

.yes {
    background-color: var(--green);
}

.no {
    background-color: var(--delete-red);
}