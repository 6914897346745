.main-layout {
    max-height: 100vh;
    max-width: 100vw;
    overflow: hidden;
}

.screen-layout {
    max-height: 60vh;
    width: 100%;
    overflow-y: auto;
}